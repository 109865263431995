import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveTickets } from '@ttstr/actions';
import { Container, ContentrArea, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Article } from '@ttstr/api';

type Props = {
  /**
   * ticketFilter can be used if you want a Route to a dedicated page for special tickets.
   *
   * Example:
   * You want a page only with tickets that has the tag id 1234.
   * The function for ticketFilter would then be
   *  p => p.tag_ids.includes(1234)
   *
   * Then create a route in App.tsx with
   * <Route exact path="/specialtickets" render={()=> <TicketView ticketFilter={p => p.tag_ids.includes(1234)} />} />
   */
  ticketFilter?: (p: Article) => any;
};

const TicketListing: React.FC<Props> = ({ ticketFilter = (t) => t }) => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="py-5 ticket-listing">
      <Container className="list-page">
        <Helmet>
          <title>{t(`TICKETLISTING.TITLE`)}</title>
          <body className="ticket-listing-page" />
        </Helmet>

        <h1 className="text-center mb-4">{t(`TICKETLISTING.TITLE`)}</h1>

        <Container>
          <ContentrArea id="before-listing" />
        </Container>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry id="ticket-listing" products={tickets.filter(ticketFilter)} type="list" />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
